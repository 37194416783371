"use client";
import React, { useState } from "react";
import fc_full_logo from "../../../public/full_fc_logo.png";
import {
  MdOutlineClose,
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowLeft,
} from "react-icons/md";
import Image from "next/image";
import Link from "next/link";

export default function MobileMenu(props) {
  // Using an object to track open sections
  const [openSections, setOpenSections] = useState({});

  const data = props.data;

  // Function to handle clicking on a dropdown
  const toggleSection = (id) => {
    setOpenSections((prevOpenSections) => ({
      ...prevOpenSections,
      [id]: !prevOpenSections[id],
    }));
  };

  return (
    <div className="mobile-menu">
      <div className="menu-header">
        <MdOutlineClose onClick={props.toggleMenu} />
        {/* Assuming props.onClose is a function to close the menu */}
        <Image src={fc_full_logo} width={500} height={500} alt="FactoryCat" />
      </div>
      <ul>
        {data.link.map((links) => {
          if (links.has_sublinks) {
            return (
              <React.Fragment key={links.id}>
                <li
                  className="dropdown"
                  onClick={() => toggleSection(links.id)}
                >
                  {links.name}
                  {openSections[links.id] ? (
                    <MdOutlineKeyboardArrowLeft />
                  ) : (
                    <MdOutlineKeyboardArrowDown />
                  )}
                </li>
                {openSections[links.id] && (
                  <ul>
                    {links.sublink.map((sublinks) => (
                      <li key={sublinks.id} onClick={props.toggleMenu}>
                        <Link href={sublinks.location}>{sublinks.link}</Link>
                      </li>
                    ))}
                  </ul>
                )}
              </React.Fragment>
            );
          } else {
            return (
              <li key={links.id} onClick={props.toggleMenu}>
                <Link href={links.location}>{links.name}</Link>
              </li>
            );
          }
        })}
        {/* Hard-coded Contact Us link */}
        <li onClick={props.toggleMenu}>
          <Link href="/contact">Contact Us</Link>
        </li>
      </ul>
    </div>
  );
}
