"use client";
import React from "react";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

export default function Tawk() {
  return (
    <TawkMessengerReact
      propertyId="665f0b1b9a809f19fb38e003"
      widgetId="1hvhk6oq7"
    />
  );
}
