"use client";

import Link from "next/link";
import { FaExternalLinkAlt } from "react-icons/fa";
import React, { useState, useEffect, useRef } from "react";
// import image from "/public/assets/34/34_alpha.png";
import { RxArrowTopRight } from "react-icons/rx";
import Image from "next/image";
import { BiSupport } from "react-icons/bi";

import { useRouter } from "next/navigation";
import { sendFormEvent } from "./_ga4/eventHandler";
export default function DesktopDropDownMenu(props) {
  const router = useRouter();
  const [visibleParts, setVisibleParts] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const serialRef = useRef(null);
  const [serialSearchStatus, setSerialSearchStatus] = useState(
    "Get detailed information on your industrial floor scrubber."
  );
  const [formStarted, setFormStarted] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const navigationData = props.data;
  const dropDownLinks = props.dropDownLinks;
  const selectedLink = props.selectedLink;
  const [selectedSubLink, setSelectedSublink] = useState(
    "Walk Behind Floor Scrubbers"
  );
  const machines = props.machines;
  const rightColumnStyle = navigationData.link.reduce((acc, link) => {
    if (
      link.name === selectedLink &&
      link.background_image &&
      link.background_image.data &&
      link.background_image.data.attributes
    ) {
      acc.backgroundImage = `url(${link.background_image.data.attributes.url})`;
    }
    return acc;
  }, {});
  const selectedLinkDetails = navigationData.link.find(
    (link) => link.name === selectedLink
  );

  const rightColumnTitle = selectedLinkDetails?.title || "Default Title";
  const rightColumnDescription = selectedLinkDetails?.description || "Default";

  useEffect(() => {
    const handleClick = () => {};

    const telLink = document.querySelector('a[href^="tel:"]');
    telLink.addEventListener("click", handleClick);

    // Cleanup function
    return () => {
      telLink.removeEventListener("click", handleClick);
    };
  }, []);

  const validateSerial = async (serial) => {
    if (!serial) {
      return false;
    }
    let response = await fetch(`/api/getSerial`, {
      method: "POST",
      body: serial,
    });
    response = await response.json();
    return response;
  };

  const handleInputChange = () => {
    if (!formStarted) {
      sendFormEvent("SERIAL_SEARCH", "START"); // Fire event when form interaction starts
      setFormStarted(true);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const serialNumber = serialRef.current.value;
    setSerialSearchStatus("Loading...");
    setDisabled(true);
    sendFormEvent("SERIAL_SEARCH", "SUBMIT");
    let serialStatus = await validateSerial(serialNumber);
    if (!serialStatus.status) {
      setSerialSearchStatus(
        serialStatus.data ||
          "Invalid serial number, please re-enter a valid serial serial number"
      );
      setDisabled(false);
      sendFormEvent("SERIAL_SEARCH", "INVALID/ERROR");
      return;
    } else {
      sendFormEvent("SERIAL_SEARCH", "SUCCESS");
      setSerialSearchStatus(
        "Get detailed information on your industrial floor scrubber."
      );
      setDisabled(false);
      setFormStarted(false);
      router.push(`/serial-results/${serialNumber}`);
      props.close();
    }
  };
  const handleSearch = (event) => {
    event.preventDefault();
    const value = event.target.value.toLowerCase();
    const parts = props.parts;
    setSearchTerm(value);
    if (value === "") {
      setVisibleParts([]); // Reset to all parts if search term is empty
    } else {
      // Filter and then slice the array to limit the results to 10
      const filteredParts = parts
        .filter((part) => part.partNo.toLowerCase().includes(value))
        .slice(0, 3);
      setVisibleParts(filteredParts);
    }
  };

  return (
    <>
      {selectedLink === "Products" && (
        <div className="products-navigation-content-wrapper">
          <div className="left-product-links">
            {dropDownLinks?.length > 0 && (
              <ul>
                {dropDownLinks.map((link, index) => (
                  <li
                    key={index}
                    onClick={() => setSelectedSublink(link.link)}
                    className={selectedSubLink === link.link ? "selected" : ""}
                  >
                    <div className="products-link">
                      <Link href="#">{link.link}</Link>
                      <RxArrowTopRight />
                    </div>
                    <p>Big Power in a compact form.</p>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="right-product-column-wrapper">
            <div className="selected-sublink">
              {selectedSubLink}
              <span className="test">
                {selectedSubLink === "Walk Behind Floor Scrubbers" && (
                  <p
                    style={{
                      padding: "0 0",
                    }}
                  >
                    Our walk behind floor scrubbers are designed to clean
                    industrial spaces quickly and efficiently. These machines
                    are perfect for cleaning small to medium-sized areas.
                  </p>
                )}
                {selectedSubLink === "Ride On Floor Scrubbers" && (
                  <p
                    style={{
                      padding: "0 0",
                    }}
                  >
                    Our ride-on floor scrubbers are designed to clean large
                    industrial spaces quickly and efficiently. These machines
                    are perfect for cleaning medium to large-sized areas.
                  </p>
                )}
                {selectedSubLink === "Ride On Floor Sweepers" && (
                  <p
                    style={{
                      padding: "0 0",
                    }}
                  >
                    Our ride-on floor sweepers are designed to clean large
                    industrial spaces quickly and efficiently. These machines
                    are perfect for cleaning medium to large-sized areas.
                  </p>
                )}
                {selectedSubLink === "Walk Behind Floor Sweepers" && (
                  <p
                    style={{
                      padding: "0 0",
                    }}
                  >
                    Our walk behind floor sweepers are designed to clean small
                    to medium-sized industrial spaces quickly and efficiently.
                    These machines are perfect for cleaning small to
                    medium-sized areas.
                  </p>
                )}
              </span>
            </div>
            <div className="right-product-content">
              {machines.map((machine) => {
                if (
                  machine.attributes.style.data.attributes.style ===
                  selectedSubLink
                ) {
                  return (
                    <Link
                      href={`/models/${machine.attributes.slug}`}
                      onClick={props.close}
                      key={machine.attributes.id}
                      className="machine"
                    >
                      <Image
                        src={machine.attributes.main_image.data.attributes.url}
                        width={180}
                        height={180}
                        alt={
                          machine.attributes.main_image.data.attributes
                            .alternativeText ||
                          `FactoryCat ${machine.attributes.name} || ${machine.attributes.style.data.attributes.style}`
                        }
                      />
                      <h3>{machine.attributes.name}</h3>
                    </Link>
                  );
                }
              })}
            </div>
          </div>
        </div>
      )}
      {selectedLink === "Support" && (
        <div className="support-navigation-content-wrapper">
          <ul className="support-left-subnavigation">
            {navigationData.link
              .find((link) => link.name === "Support")
              ?.sublink.map((sublink) => (
                <li
                  key={sublink.id}
                  className={
                    sublink.link === "Serial Search" ? "serial-search" : ""
                  }
                  onClick={() => {
                    if (sublink.link !== "Serial Search") {
                      router.push(sublink.location);
                      props.close();
                    }
                  }}
                >
                  {sublink.link === "Serial Search" ? (
                    <>
                      <div className="sublink-card-content">
                        <div className="sublink-card-title">
                          <h3>{sublink.link}</h3>
                          <RxArrowTopRight />
                        </div>
                        {serialSearchStatus && <p>{serialSearchStatus}</p>}
                      </div>

                      <form className="navigation-serial-search">
                        <input
                          type="text"
                          id="serial"
                          name="serial"
                          onChange={handleInputChange}
                          ref={serialRef}
                          placeholder="Enter Serial Number"
                          autoComplete="off"
                        />
                        <button
                          className="search-button"
                          type="submit"
                          onClick={handleSubmit}
                          disabled={disabled}
                        >
                          Search
                        </button>
                      </form>
                    </>
                  ) : (
                    <Link href={sublink.location} onClick={() => props.close()}>
                      {/* <div className="sublink-card-icon">
                    {sublink.icon_type === "registration" && (
                      <PiBookOpenTextDuotone />
                    )}
                    {sublink.icon_type === "contact" && <BiSupport />}
                    {sublink.icon_type === "about" && <GrInfo />}
                    {sublink.icon_type === "serial" && <CgSearchFound />}
                    {sublink.icon_type === "downloads" && (
                      <LiaFileDownloadSolid />
                    )}
                    {sublink.icon_type === "parts-search" && (
                      <TbSettingsSearch />
                    )}
                  </div> */}
                      <div className="sublink-card-content">
                        <div className="sublink-card-title">
                          <h3>{sublink.link}</h3>
                          <RxArrowTopRight />
                        </div>
                        <p>{sublink.description}</p>
                      </div>
                      {sublink.link === "Contact us" && (
                        <div className="sublink-card-icon">
                          <BiSupport />
                        </div>
                      )}
                    </Link>
                  )}
                </li>
              ))}
          </ul>

          {/* <div className="support-two-col-span">
            <h3>We are committed to providing excellent customer service</h3>
            <p>
              Our goal is to provide you with the best customer service
              experience. If you have any questions or concerns, please contact
              us.
            </p>
          </div> */}
        </div>
      )}
      {selectedLink === "Gallery" && (
        <div className="gallery-navigation-content-wrapper">
          <div className="gallery-navigation-left">
            <div className="navigation-gallery-title-section">
              <h2>
                FactoryCat - <span>Gallery</span>
              </h2>
              <p>
                View our gallery to see our floor scrubbers and sweepers in
                action. You can also view our machines in different environments
                and settings.
              </p>
            </div>
            <Link
              href="/gallery"
              onClick={props.close}
              className="navigation-gallery-link"
            >
              View Gallery
            </Link>
          </div>
          <div className="gallery-navigation-right">
            <div className="gallery-navigation-images-wrapper">
              {props.demoImages.map((image, index) => {
                return (
                  <Image
                    key={index}
                    src={image.fileLink}
                    width={500}
                    height={500}
                    title={image.title}
                    alt={image.altText}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}
      {selectedLink === "Parts Shop" && (
        <div className="parts-shop-navigation-content-wrapper">
          <div className="parts-shop-navigation-left">
            <div className="parts-shop-navigation-title-section">
              <h2>
                <span className="model">FactoryCat -</span>{" "}
                <span className="type">Parts Shop</span>
              </h2>
              <p>
                FactoryCat floor scrubber parts are available for purchase
                online. We have a large selection of parts available for
                purchase. If you need help finding a part, please contact us.
              </p>
            </div>
            <div className="parts-shop-content-section">
              <form>
                <input
                  type="text"
                  placeholder="Search by part number"
                  value={searchTerm}
                  onChange={handleSearch}
                  className="search-input"
                />
                {/* <button
                  className="search-button"
                  onSubmit={handleSearch}
                  type="submit"
                >
                  Search
                </button> */}
              </form>
              <div className="parts-shop-external-section">
                <p>
                  You can also visit our official parts shop to view our large
                  selection of parts, including brushes, squeegees, and more.
                </p>
                <Link
                  className="external-parts-shop-link"
                  href="https://rps-shop.com"
                  target="_blank"
                  onClick={props.close}
                >
                  Go to Parts Shop
                  <FaExternalLinkAlt />
                </Link>
              </div>
            </div>
          </div>
          <div className="parts-shop-navigation-right">
            <div className="parts-shop-results-section">
              <div className="parts-shop-results-title-section">
                {visibleParts === null ? (
                  <h2>Search for a part to display the results.</h2>
                ) : (
                  <h2>Search Results</h2>
                )}
              </div>
              {visibleParts &&
                visibleParts?.map((part) => (
                  <Link
                    className="navigation-part-link"
                    key={part.partNo}
                    href={`/parts/${part.partNo}`}
                    onClick={() => {
                      props.close();
                    }}
                  >
                    <div className="part-details">
                      <p className="partNumber">
                        <span>{part.partNo}</span>
                      </p>
                      <RxArrowTopRight />
                    </div>
                    <p>{part.description && part.description} </p>
                  </Link>
                ))}
              {visibleParts && visibleParts?.length === 0 && (
                <>No parts found</>
              )}
            </div>
          </div>
        </div>
      )}
      {selectedLink === "Login" && (
        <div className="login-navigation-content-wrapper">
          {/* <div className="login-navigation-left">
            <div className="login-title-section">
              <h2>
                <span className="red">Dealer</span> Resources
              </h2>
              <p>
                FactoryCat dealers can log in to access dealer-specific
                resources and information. If you are a dealer and need access,
                please contact us.
              </p>
            </div>
            <div className="login-content-wrapper">
              <ul>
                <li>
                  Leads
                  <p>
                    Access your leads and view detailed information about your
                    leads.
                  </p>
                </li>
                <li>
                  Sales
                  <p>
                    View sales information and access sales resources and
                    materials.
                  </p>
                </li>
                <li>
                  Marketing
                  <p>
                    Access marketing resources and materials to help promote
                    FactoryCat products.
                  </p>
                </li>
                <li>
                  Support
                  <p>
                    Access to support resources and materials to help you
                    provide excellent customer service.
                  </p>
                </li>
              </ul>
            </div>
          </div> */}
          <div className="login-navigation">
            <div className="login-background-wrapper">
              <Image
                src="/Scanchion.png"
                width={500}
                height={500}
                alt="FactoryCat Scanchion"
              />
              <div className="login-wrapper">
                <div className="login-section">
                  <div className="login-title-section">
                    <h2>
                      Dealer <span className="red">Access</span>
                    </h2>
                    <p>Login to your FactoryCat dealer account.</p>
                  </div>
                  <Link
                    href="https://portal.rpscorporation.com"
                    className="login-button"
                    target="_blank"
                    onClick={props.close}
                  >
                    Login
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
