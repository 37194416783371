"use client";
import React, { useState } from "react";
import Image from "next/image";
import fc_full_logo from "../../../public/full_fc_logo.png";
import { MdMenu } from "react-icons/md";
import MobileMenu from "./MobileMenu";
import { useRouter } from "next/navigation";
export default function MenuWrapper(props) {
  const data = props.data.data[0].attributes;
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const router = useRouter();
  const handleShowMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };
  return (
    <>
      <div className="mobile-navigation">
        <MdMenu onClick={handleShowMenu} />
        <Image
          src={fc_full_logo}
          width={500}
          height={500}
          alt="FactoryCat"
          onClick={() => router.push("/")}
        />
      </div>
      <div
        className={
          showMobileMenu
            ? "mobile-menu-wrapper open"
            : "mobile-menu-wrapper close"
        }
      >
        <MobileMenu data={data} toggleMenu={handleShowMenu} />
      </div>
    </>
  );
}
